.scroll-container {
  width: 100%;
  overflow-x: scroll;

  /* hide scrollbar */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
}
