@use 'styles/color';

.icon-up {
  transition: transform 0.5s;
  transform: rotate(-180deg);
}

.icon-down {
  transition: transform 0.5s;
  transform: rotate(0deg);
}

.unselect-row {
  display: flex;
  padding: 16px 22px 16px 16px;
  flex-direction: row;
  align-items: center;
}

.unselect-row-title {
  flex-grow: 1;
  font-size: 15px;
  color: color.$soft-orange;
}

.parent-category-row {
  display: flex;
  padding: 6px 22px 6px 16px;
  flex-direction: row;
  align-items: center;

  &.highlight {
    background-color: color.$linen;
  }
}

.parent-category-icon {
  width: 42px;
  height: 42px;
  padding: 8px;
  margin-right: 12px;
  background-color: color.$gray10;
  border-radius: 24px;
}

.parent-category-name {
  flex-grow: 1;
  font-size: 15px;
  color: color.$black;
}

.child-category-row {
  display: flex;
  padding: 16px 22px 16px 70px;
  flex-direction: row;
  align-items: center;
  border-bottom: 0.5px solid color.$gray20;
  &:last-child {
    border-bottom: none;
  }
}

.child-category-name {
  flex-grow: 1;
  font-size: 15px;
  color: color.$black;
}

.product-group-list {
  padding-left: 86px;
  background-color: color.$gray10;
  border-bottom: 0.5px solid color.$gray20;
}

.product-group-row {
  display: flex;
  padding: 16px 22px 16px 0;
  flex-direction: row;
  align-items: center;
  border-bottom: 0.5px solid color.$gray20;
  &:last-child {
    border-bottom: none;
  }
}

.product-group-name {
  flex-grow: 1;
  font-size: 15px;
  color: color.$soft-orange;
}
