@use 'styles/color';

// 各Item間のマージンが1pxで、1行に3つのItemが入る
$ranking-item-width: calc((100vw - 1px * 2) / 3);

.ranking-item-container {
  display: block;
  position: relative;
  width: $ranking-item-width;
  text-decoration: none;
  transition: 0.3s;

  &:hover {
    text-decoration: none;
  }

  &:active {
    opacity: 0.6;
  }
}

.ranking-badge {
  position: absolute;
  left: 4px;
  top: 4px;
  width: 20px;
  height: 20px;
  background-color: color.$soft-orange;
  border-radius: 10px;
  color: white;
  text-align: center;
  line-height: 20px;
  z-index: 1;
}

.ranking-link {
  &:hover {
    text-decoration: none;
  }
}

.price-fav-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
