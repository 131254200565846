.black-filter {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(var(--vh) * 100);
  background-color: black;
  opacity: 0.7;
  z-index: 10;

  &.visible {
    display: block;
  }
}
