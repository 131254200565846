@use 'styles/color';

.calendar {
  /* 新しいstacking contextを作成する */
  position: relative;
  z-index: 0;

  .monthly-calendar {
    margin-top: 14px;
  }

  .monthly-calendar-title {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    color: color.$gray90;
  }

  .cell-container {
    display: flex;
    position: relative;
    margin-top: 10px;
    flex-wrap: wrap;
  }
}

.cell {
  display: inline-block;
  position: relative;
  width: calc(100vw / 7);
  height: 70px;

  .date-text {
    position: relative;
    margin: 0 auto;
    width: 37px;
    height: 37px;
    font-size: 12px;
    font-weight: 700;
    color: color.$gray90;
    text-align: center;
    line-height: 37px;

    &.disabled {
      color: color.$gray30;
    }

    &.selected {
      background-color: color.$linen;
      border: 2px solid color.$soft-orange;
      border-radius: 20px;
      line-height: 33px;
    }

    .annotation {
      position: absolute;
      top: -2px;
      right: -2px;
      font-size: 12px;
      font-weight: 700;
      line-height: 12px;
      color: color.$soft-red;
    }
  }
}

.bubble {
  display: flex;
  position: relative;
  margin: 2px auto 0;
  width: 48px;
  height: 31px;
  border-radius: 4px;
  background-color: color.$white;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.16));

  &:after {
    content: '';
    position: absolute;
    top: -5px;
    right: 0;
    left: 0;
    width: 0px;
    height: 0px;
    margin: auto;
    border-style: solid;
    border-color: transparent transparent color.$white transparent;
    border-width: 0 5px 5px 5px;
  }

  .bubble-text {
    height: fit-content;
    margin: auto;
    font-size: 10px;
    line-height: 12px;
    font-weight: 700;
    text-align: center;

    &.underline {
      text-decoration: underline;
    }

    &.soft-orange {
      color: color.$soft-orange;
    }
    &.dark-red {
      color: color.$dark-red;
    }
    &.gray80 {
      color: color.$gray80;
    }
  }
}

.expanded-event-list {
  position: absolute;
  z-index: 1;
  top: 78px;
  padding: 6px;
  background-color: color.$white;
  border-radius: 8px;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.16));

  &.left {
    left: 6px;
  }
  &.right {
    right: 6px;
  }
  &.center {
    left: 50%;
    transform: translateX(-50%);
  }

  .close-btn {
    position: absolute;
    top: -8px;
    right: -8px;
    width: 30px;
    height: 30px;
    background-color: color.$white;
    border-radius: 15px;

    .close-btn-content {
      position: absolute;
      top: 5px;
      left: 5px;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      border: 2px solid color.$gray30;

      &:before {
        position: absolute;
        content: '';
        width: 10px;
        height: 2px;
        top: 7px;
        left: 3px;
        transform: rotate(-45deg);
        background-color: color.$gray30;
      }
      &:after {
        position: absolute;
        content: '';
        width: 10px;
        height: 2px;
        top: 7px;
        left: 3px;
        transform: rotate(45deg);
        background-color: color.$gray30;
      }
    }
  }

  .expanded-event-item {
    display: flex;
    padding: 8px;

    .image {
      display: block;
      width: 55px;
      height: 55px;
      border-radius: 30px;
    }

    .event-name-box {
      display: flex;
      width: 140px;
      margin-left: 16px;
      flex-direction: column;
      justify-content: space-evenly;

      .text {
        width: 100%;
        font-size: 14px;
        font-weight: 700;
        color: color.$gray90;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    margin: 4px 0;
    background-color: color.$gray20;
  }
}
