@use 'styles/color';

.suboption-checkbox-container {
  text-align: left;
  letter-spacing: -0.4em;
  & > div:nth-of-type(3n + 2) {
    border-right: solid 1px color.$gray20;
  }
}

.suboption-checkbox-cell {
  display: inline-block;
  width: 50%;
  letter-spacing: normal;
}

.extra-option {
  position: relative;

  .extra-option-bubble-box {
    position: absolute;
    top: -86px;

    .extra-option-bubble-card {
      display: flex;
      position: relative;
      padding: 16px;
      border-radius: 4px;
      background-color: color.$white;
      filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.25));

      &::after {
        content: '';
        position: absolute;
        top: 86px;
        left: 228px;
        width: 0px;
        height: 0px;
        margin: auto;
        border-style: solid;
        border-color: color.$white transparent transparent transparent;
        border-width: 8px 8px 0 8px;
      }

      .extra-option-bubble-text {
        height: fit-content;
        margin: auto;
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        color: color.$black;
      }
    }
  }

  .extra-option-help-circle {
    padding: 12px 18px 8px 9px;
  }
}
