@use 'styles/color';

.container {
  background-color: color.$white;
}

.top-title {
  margin-top: 24px;
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  color: color.$gray90;
}

.area-select-section {
  margin-top: 16px;
  padding: 0 16px;
  display: flex;
  align-items: center;

  .area-select-section-title {
    margin-left: 3px;
    text-align: left;
    font-size: 14px;
    color: color.$gray90;
  }

  .area-select-section-selector {
    margin-left: 16px;
    flex-grow: 1;
  }
}

.express-delivery-notice {
  margin: 16px 16px 0;
  padding: 6px;
  border-radius: 4px;
  background-color: color.$gray10;
  text-align: left;
  font-size: 11px;
  color: color.$gray90;

  .attention {
    font-size: 12px;
    color: color.$soft-red;
  }

  .bold {
    font-weight: 700;
  }
}

.weekdays {
  display: flex;
  margin-top: 16px;
  justify-content: space-around;
  font-size: 12px;
  font-weight: 700;
  color: color.$gray90;

  .sunday {
    color: color.$red;
  }

  .saturday {
    color: color.$blue;
  }
}
