@use 'styles/scrollbar';

.window {
  position: relative;
  overflow-x: hidden;
  width: 100vw;
}

.child-view-container {
  transform: translateX(calc(-100vw * var(--view-idx)));
  position: relative;
  top: 0;
  display: flex;
}

.smooth {
  transition: transform 0.2s;
}

.child-view {
  width: 100vw;
  height: var(--height, 'auto');
  max-height: var(--height, 'none');
  min-height: var(--height, 'none');
  overflow-y: scroll;
  flex-shrink: 0;

  @include scrollbar.hide;
}
