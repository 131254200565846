.window {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100vw;
}

.pages {
  display: flex;
}

.page {
  width: 100vw;
  flex-shrink: 0;
}
