@use 'styles/color';
@use 'styles/scrollbar';

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  transform: translate3d(0, var(--offsetY), 0);
  transition: transform 0.1s;

  &.touching {
    transition: none;
  }
}

.top-space {
  position: relative;
  width: 100vw;
  height: 30px;
  min-height: 30px;
  z-index: 10;

  /* DownArrow */
  &:after,
  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 13px;
    height: 4px;
    width: 20px;
    background-color: color.$white;
    border-radius: 10px;
  }
  &:before {
    right: calc(50% - 2px);
    transform: rotate(25deg);
  }
  &:after {
    left: calc(50% - 2px);
    transform: rotate(-25deg);
  }
}

.content {
  overflow-y: scroll;
  height: fit-content;
  max-height: calc(var(--vh) * 100 - 30px);
  background-color: color.$white;

  &.full {
    min-height: calc(var(--vh) * 100 - 30px);
  }

  @include scrollbar.hide;
}
