.container {
  position: relative;
  width: 100%;
  padding: 16px 0;
  background-color: white;

  .left-items {
    position: absolute;
    display: flex;
    left: 0;
    top: 0;
  }

  .right-items {
    position: absolute;
    display: flex;
    right: 0;
    top: 0;
  }
}
