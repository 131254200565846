@use 'styles/color';

.container {
  position: relative;
}

.select {
  width: 100%;
  height: 40px;
  padding: 9px 16px;
  border: 1px solid color.$gray20;
  border-radius: 4px;
  font-size: 15px;

  /* 下矢印を消す */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  select::-ms-expand {
    display: none;
  }
}

.icon {
  position: absolute;
  right: 4px;
  top: 5px;
  pointer-events: none;
}
