@use 'styles/color';

.container {
  position: sticky;
  bottom: 0;
  z-index: 1;
  display: flex;
  width: 100%;
  padding: 16px;

  &.opaque {
    border-top: 0.5px solid color.$gray20;
    background-color: color.$white;
  }
}

.clear-btn {
  height: 48px;
  padding: 14px 10px;
  font-size: 13px;
  color: color.$gray50;
  line-height: 18px;
  text-align: center;
  border: 1px solid color.$gray20;
  border-radius: 8px;
  background-color: color.$white;
}

.submit-btn {
  flex-grow: 1;
  margin-left: 16px;
  height: 48px;
  font-size: 15px;
  font-weight: 700;
  color: color.$white;
  line-height: 48px;
  text-align: center;
  border-radius: 8px;
  background-color: color.$soft-orange;
}
