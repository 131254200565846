@use 'styles/color';

.dual-slider {
  position: relative;
  height: 40px;
}

@mixin slider-thumb-design() {
  /* デフォルトのスタイルを解除 */
  -webkit-appearance: none;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border: none;
  border-radius: 16px;
  background: linear-gradient(to right, color.$white 36%, color.$gray30 36% 40%, color.$white 40% 48%, color.$gray30 48% 52%, color.$white 52% 60%, color.$gray30 60% 64%, color.$white 64%);
  background-size: 100% 33%;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  /* つまみに対するイベントだけ受け付ける */
  pointer-events: auto;
}

input.slider {
  /* デフォルトのスタイルを解除 */
  appearance: none;
  -webkit-appearance: none;
  position: absolute;
  /* Containerの高さからSliderの高さを引いたものの半分 */
  top: calc((40px - 3px) / 2);
  left: 0;
  width: 100%;
  height: 3px;
  margin: 0;
  border-radius: 2px;

  outline: none;
  background: linear-gradient(to right, color.$gray20 var(--bg-lower), color.$soft-orange var(--bg-lower) var(--bg-upper), color.$gray20 var(--bg-upper));
  &:nth-child(2) {
    background: none;
  }
  /* スライドバーに対するイベントを停止 */
  pointer-events: none;

  /* -webkit 向けのつまみデザイン */
  &::-webkit-slider-thumb {
    @include slider-thumb-design();
  }

  /* -moz 向けのつまみデザイン */
  &::-moz-range-thumb {
    @include slider-thumb-design();
  }

  /* Firefoxで点線が周りに表示されてしまう問題の解消 */
  &::-moz-focus-outer {
    border: 0;
  }
}
