@use 'styles/color';

.items-row-window {
  width: 100vw;
  overflow-x: scroll;

  &.unscroll {
    overflow-x: hidden;
  }

  &::-webkit-scrollbar {
    height: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: linear-gradient(to right, color.$white 16px, color.$gray50 16px calc(100% - 16px), color.$white calc(100% - 16px));
    border-radius: 2px;
  }
}

.image-container {
  width: 56px;
  height: 56px;
  background-color: color.$gray10;
  border: 3px solid color.$gray10;
  border-radius: 35px;

  &.selected {
    background-color: color.$linen;
    border: 3px solid color.$soft-orange;
  }
}
